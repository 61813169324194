/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import '../node_modules/bootstrap/scss/bootstrap-reboot.scss';
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';



html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
